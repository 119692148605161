<template>
<div>
    <sub-header :backButton="true" :handleClick="showPackageLinkModal" :showBtn="clientId" buttonText="Attach Packages" /> 

    <div  class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6">
        <span v-if="isLoading" class="flex h-44 min-w-full bg-white items-center justify-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
        </span>
        <div  v-else class="bg-white rounded-sm p-6 flex flex-col gap-5">
            


            <FormulateForm #default="{ hasErrors }">
                <div class="card-body">
                  <div class="form flex w-full flex-col">
                    <FormulateInput
                      class="w-full"
                      label="Client name"
                      placeholder="Client name"
                      type="text"
                      validation="required"
                      v-model="client.name"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Telephone"
                      placeholder="Telephone"
                      type="tel"
                      validation="required|number"
                      v-model="client.telephone"
                    />
                    <FormulateInput
                      class="w-full"
                      label="VAT registration no."
                      placeholder="VAT registration no."
                      type="text"
                      validation="required|number|min:0,number"
                      v-model="client.vat_registration_no"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Retention period (Should be in Days)"
                      placeholder="Retention period"
                      type="text"
                      min="0"
                      validation="required|number|min:0,number"
                      v-model="client.retention_period"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Primary contact no."
                      placeholder="Primary contact no."
                      type="text"
                      minLength="10"
                      maxLength="10"
                      validation="required|number|min:10,length|max:10,length"
                      v-model="client.primary_contact_no"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Max users."
                      placeholder="Max users."
                      type="text"
                      min="0"
                      validation="required|number|min:0,number"
                      v-model="client.max_users"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Max candidates."
                      placeholder="Max Subject Number"
                      type="text"
                      min="0"
                      validation="required|number|min:0,number"
                      v-model="client.max_candidate"
                    />
                    <FormulateInput
                      class="w-full"
                      label="Client prefix"
                      placeholder="Client prefix will be used to identify cases"
                      type="text"
                      validation="required|min:3,length|matches:/[a-z]/"
                      :validation-messages="{ matches: 'Prefix must be lowercase.' }"
                      v-model="client.prefix"
                    />
                    <FormulateInput
                        v-if="!clientId"
                      class="w-full"
                      label="First admin email"
                      placeholder="First admin email"
                      type="email"
                      validation="required|email"
                      v-model="client.admin.email"
                    />
                    <FormulateInput
                      v-if="!clientId"
                      class="w-full"
                      label="First admin name"
                      placeholder="First admin name"
                      type="text"
                      validation="required"
                      v-model="client.admin.user_name"
                    />
                  </div>

                  <div class="mt-4 flex justify-between max-w-full gap-4">
                    <div class="flex flex-col gap-2" v-if="clientId">
                        <span class="text-gray-600 text-md"> Packages Linked with client </span>
                        <div class="flex gap-2" v-if="clientPackages.length">
                            <span class="px-2 py-1 text-xs rounded-md font-normal bg-green-100 items-center text-green-800 flex gap-3" v-for="clientPacakge in clientPackages" :key="clientPacakge.id">{{clientPacakge.package_name || clientPacakge.id}}  </span>
                        </div>
                        <span v-else class="text-sm text-gray-400 bg-gray-100 px-3 py-1 rounded-md">No package linked with client</span>
                    </div>
            </div>
            
                  <div class="card-actions justify-end">
                    <Button
                      :text="clientId ? 'Update' : 'Create' "
                      type="primary"
                      :disabled="hasErrors || clientCreationStarted"
                      @click="submitForm"
                    />
                  </div>
                </div>
              </FormulateForm>

            
        </div>
        
    </div>

    <!-- delete client packages confirm -->

      <modal-confirm
      title="Are you sure?"
      message="Please confirm you're about to delete package from client."
      ref="package-client-remove"
    ></modal-confirm>



    <!-- link client with screening package Modal -->
    <modal-content
      ref="package-link-modal"
      :max-width="480"
      name="packagelink"
      title="Attach packages to client"
      @close="closePackageLinkModal"
      :showFooter="false"
    >
      <template #content>
        <div class="flex-1 overflow-hidden" >
          <div class="flex flex-col mb-4">
              <neo-input
                    placeholder="Search Package"
                    bg="white"
                    name="search-package"
                    v-model="searchPackage"

                >
                </neo-input>
          </div>
          <div style="max-height:250px; min-height:100px;" class="overflow-y-auto  p-2 border border-solid border-gray-300 rounded-md" v-if="packages">
              <div class="p-2 flex gap-2 items-center justify-between " style="border-bottom:1xp solid gray;" v-for="el in filterPackages" :key="el.id">
                    <div class="flex items-center">
                        <loader :size="4" v-if="isPackageLinking.loading && isPackageLinking.id === el.id" /> 
                        <label class="truncate" :for="el.id"> {{el.package_name || el.id}} </label>
                    </div>
                    <input type="checkbox" class="checkbox checkbox-primary" :disabled="isPackageLinking.loading" :checked="el.added" @change="handleLinkPackage(el)" :id="el.id" />
                        
              </div>
              <div class="text-gray-400 flex items-center justify-center" v-if="!filterPackages.length">No Package Found</div>
              
          </div>
        </div>
      </template>
    </modal-content>

</div>

</template>

<script>
import axios from "@/axios";
// import loader from "@/components/loader";
import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import modalContent from "@/components/modal-content";
// validation Library
import { required, minLength } from 'vuelidate/lib/validators'
import loader from "@/components/loader";
const modalConfirm = () => import("@/components/modal-confirm");
export default {
    name:"create-client",
    components: {
        Button,
        SubHeader,
        modalContent,
        loader,
        modalConfirm,

        // axios,
        // loader,
    },
    data(){
        return {
            clientCreationStarted:false,
              client: {
                name: "",
                telephone: "",
                vat_registration_no: "",
                registration_no: "",
                retention_period: "",
                primary_contact_no: "",
                max_users: "",
                max_candidate: "",
                // "address": {
                //     "line_1": "",
                //     "line_2": "",
                //     "city": "",
                //     "zip_code": "",
                //     "active": true
                // },
                prefix: "",
                active: true,
                admin: {
                    email: "",
                    user_name: "",
                    active: true,
                    mobile: ""
                }
            },
            isLoading:false,
            clientId: null,
            packages: [], // all packages
            clientPackages:[],  //packages assigned to user
            searchPackage: "",
            isPackageLinking: {
                loading: false,
                id: null
            },
        }
    },
    validations: {
        client: {
            name: {
                required
            },
            telephone: {
                required
            },
            vat_registration_no:{
                required,
            },
            retention_period:{
                required
            },
            primary_contact_no:{
                required
            },
            prefix:{
                required,
                minLength: minLength(3)
            },

            


        }
  
    },

    computed:{
        filterPackages(){
            let searchvalue = this.searchPackage
            if(searchvalue){
                let new_package = this.packages.filter((ele) =>
                ele.package_name.toLowerCase().includes(searchvalue.toLowerCase())
                );
                return new_package;
            }else{
                return this.packages
            }
        }
    },
  
    async mounted() {
        if (!this.$store.getters.getTenantId) {
                await this.$store.dispatch('fetchTenantId')
            }
        this.clientId = this.$route.params.id
        if(this.clientId){
            await this.getClient()
            await this.getAllClientPackages()
            this.packages = await this.getPackages()
           
        }
        console.log(this.packages, "packghes")
    },

    methods:{
        async submitForm(){
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log(this.$v)
            }else{
                if(this.clientId) {
                    this.updateClient()
                }else{
                    this.createClient()
                }
            }
        },
        async createClient(){
            
            // const payload = {};
            const url = "/client";
            this.clientCreationStarted = true

            try{
                const data = await axios.post(url, this.client);
                this.client = data.data
                this.$toast.success("Client created");
                this.clientCreationStarted = false
                this.$router.push({
                    name: "Clients List",
                });
            }
            catch(error){
                this.clientCreationStarted = false
                this.$toast.warning("Client creation failed !");

            }
            this.clientCreationStarted = false
            this.$$forceUpdate()

           
        },
        
        // Get Client Data

        async getClient(){
            this.isLoading = true
            const url = `/client/${this.clientId}`;
            try{
                const data = await axios.get(url);
                console.log(data.data, "Data")
                let clientAdmins = await this.getClientAdmins()
                console.log(clientAdmins, "Client Admins")
                this.client = data.data
            }
            catch(error){ 
                 this.$toast.warning("Client not found !");
            }
            this.isLoading = false

        },

        async getClientAdmins(){
            const url = `/client/${this.clientId}/admins`;
            try{
                const data = await axios.get(url);
                return  data.data
            }
            catch(error){ 
                 console.log("error")
            }
        },

        // Update Client Data
        
        async updateClient(){
            const url = "/client";
            this.clientCreationStarted = true
            try{
                await axios.put(url, this.client);
                this.$toast.success("Client updated");
                this.clientCreationStarted = false
            }
            catch(error){
                this.clientCreationStarted = false
                this.$toast.warning("Client update failed !");

            }
            this.clientCreationStarted = false
        },

        // get packges
        async getPackages(){
            const url = `/screening-package`;
            try{
                const data = await axios.get(url);
                let packages = []
                data.data.map(element => {
                    let packageData =  {...element}
                    let isPackageExist = this.clientPackages.find(el => el.id === element.id)
                    if(isPackageExist){
                        packageData = {...packageData, added:true}
                    }else{
                        packageData = {...packageData, added:false}
                    }
                    packages.push(packageData)
                })
                return  packages
            }
            catch(error){ 
                 this.$toast.warning("Packages not Found");
            }
            this.$forceUpdate()
        },

        async handleLinkPackage(packageData){
            this.isPackageLinking = {
                loading: true,
                id: packageData.id
            }
            if(packageData.added){
                await this.removepackageFromClient(packageData)
            }else{
                const url = `/screening-package/${packageData.id}/${this.client.id}`;
                try{
                    await axios.put(url);
                    this.clientPackages.push(packageData)
                    this.$toast.success("Package linked with client");
                }
                catch(error){ 
                    this.$toast.warning("Error while attaching package with client !");
                }
                
            }
            this.packages = await this.getPackages()
            this.isPackageLinking = {
                loading: false,
                id: null
            }
        },


        // getClientPackage

        async getClientpackageInfo(packageId){
            const url = `/screening-package/${packageId}`;
            try{
                let {data} = await axios.get(url);
                return data
                
            }
            catch(error){ 
                console.log(error)
            }
        },
        // get all Client package list
        async getAllClientPackages(){
            const url = `/screening-package/client/${this.client.id}`;
            try{
                let {data} = await axios.get(url);
                console.log(data, "all client packages")
                this.clientPackages = data
                
            }
            catch(error){ 
                console.log("error>>", error)
            }
        },

        // handle remove packge from client
        async removepackageFromClient(packageData, showConfirm){
            if(showConfirm){
                const promise = await this.$refs["package-client-remove"].show({
                    title: "Are you sure?",
                    message:
                    "Please confirm you're about to delete package from client.",
                });
                if (promise) {
                    await this.removePackage(packageData)
                }
                this.$refs["package-client-remove"].close()
                
            }
            else{
                await this.removePackage(packageData)
            }
            this.isPackageLinking = {
                loading: false,
                id: null
            }
            
        },

        async removePackage(packageData){
            const url = `/screening-package/${packageData.id || packageData.id}/${this.client.id}`;
            try{
                let {data} = await axios.delete(url);
                this.clientPackages = this.clientPackages.filter(el => el.id !== data.package_id)
                this.$toast.success(data.detail);
            }
            catch(error){ 
                this.$toast.warning("Error while Removing Client from packages !");
            }
        },
        

        // section Modal control
        closePackageLinkModal(){
            this.$refs["package-link-modal"].hideModal();
        },

        showPackageLinkModal() {
            this.$refs["package-link-modal"].showModal();
        },

        // End Section Modal control
        }
}
</script>

<style scoped>
    .form-group--error{
        border: 1px solid rgb(212, 92, 92) !important;
    }
</style>